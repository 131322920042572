import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section<{ bgColor: string }>`
  z-index: 2;
  ${(props) =>
    props.bgColor === 'primary' &&
    css`
      background-color: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.light};
    `}
`

const Columns = styled.div``

const Content = styled(ParseContent)<{
  headingSize: string
  currentIndex?: number
}>`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 1200px) {
      ${(props) =>
        props.headingSize === 'huge' &&
        css`
          position: absolute;
          font-size: 100px;
          line-height: 100px;
          margin-bottom: 2rem !important;
          padding-right: 2rem;
          color: ${({ theme }) => theme.color.light};

          ${props.currentIndex === 3 &&
          css`
            position: absolute;
            z-index: -1;
            left: -200px;
          `}
        `}

      ${(props) =>
        props.headingSize === 'big' &&
        css`
          font-size: ${({ theme }) => theme.font.size.master};
          line-height: ${({ theme }) => theme.font.size.master};
          margin-bottom: 2rem !important;
          padding-right: 2rem;
        `}

    ${(props) =>
        props.headingSize === 'default' &&
        css`
          font-size: ${({ theme }) => theme.font.size.ultra};
          line-height: ${({ theme }) => theme.font.size.ultra};
          margin-bottom: 2rem !important;
          padding-right: 2rem;
        `}
    }
  }

  & p {
    ${(props) =>
      props.headingSize === 'huge' &&
      css`
        @media (min-width: 1200px) {
          margin-top: 5rem;
        }
      `}
  }
`

const TextColumns: React.FC<TextProps> = ({ fields }) => {
  return (
    <Section
      bgColor={fields.bgcolor || 'transparent'}
      className="position-relative"
    >
      <div className="container pt-5">
        {fields.description && (
          <Content
            content={fields.description}
            headingSize={fields.headingsize || 'default'}
          />
        )}

        <div className="row justify-content-center">
          <div className={`col-xl-${fields.containerwidth}`}>
            <Columns className="row justify-content-center">
              {fields.columns?.map((column, index) => (
                <div
                  className={`col-sm-${column?.columnwidth} mb-5`}
                  key={index}
                >
                  <Content
                    content={column?.description}
                    headingSize={fields.headingsize || 'default'}
                    currentIndex={index}
                    className="position-relative"
                  />
                </div>
              ))}
            </Columns>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TextColumns
